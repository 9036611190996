<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Showmax Voacher 🚀">
                    <div class="text-center" v-if="load">
                        <b-spinner variant="primary" label="Text Centered" style="width: 3rem; height: 3rem;" class="mr-1" />
                    </div>
                    <b-container v-else>
              <b-form    @submit.prevent="processtransaction">
    
                        <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col cols="6" md="3" class="mb-1 mx-auto ">
                                <b-img  thumbnail size="70px" fluid :src="mainurl + showmax.image" /></b-col>
    
                        </b-row>
                  
    
                         <b-form-group label="Showmax Plan" label-for="plan" >
                            <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"  taggable push-tags :options="option" placeholder="Please Select Showmax Plan" class="select-size-lg" >
     <template slot="option" slot-scope="option">
      
       {{ option.name }} 
   </template>
    
    <template slot="selected-option" slot-scope="option">
     
       {{ option.name }} 
   </template>
                               </v-select>
                        </b-form-group>
    
                        <b-form-group  label="Plan Amount" label-for="amount" v-if="selected != null">
                            <b-input-group prepend="₦" append>
                                <b-form-input :placeholder="amount" prepend="₦" disabled size="lg" />
                            </b-input-group>
    
                        </b-form-group>
    
    
    
    
    
                        <div class="demo-inline-spacing">
                            <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="lg" block variant="primary" :disabled="load || process">
                              <b-spinner v-if="process"
          small
          type="grow"
        />  
            <span v-else> Continue</span> 
                            </b-button>
                        </div>
      </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BAvatar, BFormInput, BFormRadio, BForm, BSpinner, BCardText, BButton, BImg, BFormGroup, BContainer, BRow, BCol, BInputGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import useServices from "@/auth/jwt/userServices"
export default {
    components: {
        BCard,
        BImg,
     BInputGroup,
vSelect,
        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        ToastificationContent,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },


    data() {
        return {
            load: false,
            loadplan: false,
            option: [],
            selected: null,
            showmax: {},
            selectedairtime: null,
            mainurl: jwtDefaultConfig.mainurl,
            phone: null,
            process: false,
            pin: null,
            amount: null,
        }
    },
  
    created() {
        this.getshowmax();
        this.getshowmaxplan();

    },




    watch: {
        selected: function(value) {
 this.amount = value.price.toString();


        }
    },

    methods: {
 
        addstyle(id) {
            return this.selectedairtime == id ? "border: 0.3rem solid green" : "papa"
        },

        selectitem(id) {
            this.selectedairtime = id;
        },


        async getshowmaxplan() {
            this.loadplan = true;
            await useServices.getshowmaxplan().then((result)=> {
              if (result.data.status == true) {
                  this.option = result.data.data;

                  console.log(result.data.data)
              }else {
                     this.$swal({
                            title: 'Error!',
                            text: result.data.data,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })

              }
            });

            this.loadplan = false;
        },
         async getshowmax() {
            this.load = true;
            await useServices.getshowmax().then((result)=> {
               if (result.data.status == true) {
                   this.showmax = result.data.data;
               }
            });

            this.load = false;

        },




        async processtransaction() {

           this.process = true;
            try {
                await useServices.processshowmax({
                    planname: this.selected.name,
                    subscriptionType: this.selected.datacode,
                    amount : this.selected.price,
                 
                }).then((result) => {

  this.process = false;
                    if (result.data.data.status == true) {


                        console.log(result.data.data.hash)

                        this.$router.push({name: "transaction", params: { hash: result.data.data.hash}});

                        console.log(result.data.data)

                    } else {

                        this.$swal({
                            title: 'Error!',
                            text: result.data.data,
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            buttonsStyling: false,
                        })



                    }



                });
            } catch (error) {
                  this.process = false;
                console.log(error.response.status)
                 if (error.response.status == 422) {
           
                 var data = JSON.parse(error.response.data.data)
          for (var k in data) {
        if (data.hasOwnProperty(k)) {

              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Error`,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                      text: data[k][0],
                    },
                  })
          //  user[k] = data[k];
        }
    }
    



                }

            }

            this.process = false;



        }
    },





}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
